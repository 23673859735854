.contact-card {
  background-color: #fff;
  color: #414042;
}

.contact-card__infotitle {
  font-weight: 700;
  margin-bottom: 0;
  color: #00A79D;
}

.contact-card a {
  color: #414042;
  text-decoration: none;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.contact-card a:hover {
  color: #000;
}

.contact-card .contact-socials a {
  font-size: 26px;
}

button.btnSubmit {
  background-color: #fff;
  color: #414042;
}

button.btnSubmit:hover, button.btnSubmit:focus {
  background-color: #414042;
  color: #fff;
}

iframe {
  width: 100%;
  height: 423px;
}

@media only screen and (min-width: 992px) {
  iframe {
    width: 50%;
  }
}

.alertCol {
  min-height: 61px !important;
  display: block;
}

@media only screen and (max-width: 767px) {
  .toast {
    width: 100% !important;
  }
}
